<template>
  <div class="login">
    <div class="login_top">
      <img class="login_top" src="../../assets/mysvg.svg" alt="">
    </div>
    <div class="login_mide">
      <div class="login_son">
        <div>

          <div>
            <div class="tab_top" v-if="isUpdata">
              <div class="top_list" @click="isTab = 1, btnTab(1)" :class="isTab == 1 ? 'bf' : ''">登入</div>
              <div class="top_list" @click="isTab = 2, btnTab(2)" :class="isTab == 2 ? 'bf' : ''">注册</div>
            </div>
            <div class="top_list bf" v-else>修改密码</div>
          </div>

          <input v-if="isUpdata" class="btn_input" type="text" v-model="userPhone" @input="blur(userPhone)"
            oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" @blur="sendMobiles"
            :placeholder="placeholderPhone">
          <input v-if="isUpdata" class="btn_input" v-model="userPassword" type="password" :placeholder="placeholderWord"
            maxlength="19">

          <div class="btn_flew" v-if="isUpdata">
            <input class="btn_flew_yzm" type="text" placeholder="验证码" v-model="code" maxlength="4">
            <div>
              <div class="yzms" v-if="btnyzm" @click='btnyzms(1)'>
                <img :src="codeName" alt="">
              </div>
              <div class="yzms" v-else @click='btnyzms(2)'>点击</div>
            </div>
          </div>
          <div class="btn_ok" @click='btnLogin(isTab)' v-if="isUpdata">
            <span v-if='isTab == 1'>登入</span>
            <span v-else>注册</span>
          </div>


          <input v-if="!isUpdata" class="btn_input" v-model="user_phone" @input="blur(user_phone)"
            oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" type="text" placeholder="登录手机号">
          <input v-if="!isUpdata" class="btn_input" v-model="user_password" type="text" placeholder="原始密码"
            maxlength="19">
          <input v-if="!isUpdata" class="btn_input" v-model="m_password" type="text" placeholder="新密码" maxlength="19">
          <input v-if="!isUpdata" class="btn_input" v-model="r_password" type="text" placeholder="确认新密码" maxlength="19">


          <div class="btn_ok" v-if="!isUpdata" @click="btnDataUp()">
            <span >确定修改</span>
          </div>

          <div class="text">
            <span v-if="isUpdata" @click="btnUpdata()">修改密码</span>
            <div @click="btndr()" v-else>登入/注册</div>
          </div>
        </div>
      </div>
    </div>
    <sideslip :sideslip="sideslip"></sideslip>

  </div>
</template>

<script>
import api from '../../api/login'
import sideslip from '../../components/common/tips.vue'
import md5 from 'js-md5';

export default {
  name: 'index',
  data() {
    return {
      isTab: 1,
      isHide: false,
      // userPhone: '13941089621',
      // userPassword: 'asdfasdf',
      // code: "1234",
      userPhone: '',
      userPassword: '',

      user_phone: "",
      user_password: "",
      m_password: "",
      r_password: "",

      code: "",
      yzmCode: "",
      placeholderPhone: '输入登录手机账号',
      placeholderWord: '输入登录密码',
      codeName: "获取验证码",
      isYzmHide: true,	// 显示提示验证码 
      btnyzm: false,
      sideslip: {
        isShow: false,
        text: "",
        dataTime: 1,
      },
      isUpdata: true,
    }
  },
  components: {
    sideslip
  },
  mounted() {
    // this.btnyzm()
  },
  methods: {
    blur() {

    },
    // 手机正则
    sendMobiles() {
      var regMobiles = /^1[3456789]\d{9}$/
      if (!regMobiles.test(this.userPhone)) {
        console.log("格式不正确1")
        this.sideslip.isShow = true
        this.sideslip.text = '格式不正确'
        this.userPhone = ''
      } else {
        this.yzm()
      }
    },
    btnyzms(val) {
      if (val == 2) {
        if (!this.userPhone) {
          console.log("请输入手机号");
          this.sideslip.isShow = true
          this.sideslip.text = '请输入手机号'
          this.btnyzm = false
        } else {
          this.btnyzm = true
        }
      } else {
        this.yzm()
      }

    },
    // 验证码
    yzm() {
      api.imgValid(this.userPhone).then((res) => {
        // console.log(res);
        this.codeName = res.data.result
      })
    },
    enNull(){
      this.userPassword = ''
      this.code = ''
      this.user_phone='' 
      this.user_password='' 
      this.m_password='' 
      this.r_password='' 
      this.userPhone = ''
    },
    btnUpdata() {
      this.isUpdata = false
      this.enNull()
    },
    btndr() {
      this.isUpdata = true
      this.enNull()
    },
    btnDataUp() {
      if (!this.user_phone) {
        this.sideslip.isShow = true
        this.sideslip.text = "输入登录手机号"
      } else if (!this.user_password) {
        this.sideslip.isShow = true
        this.sideslip.text =  "输入原始密码"
      } else if (!this.m_password) {
        this.sideslip.isShow = true
        this.sideslip.text =  "输入新密码"
      } else if (!this.r_password) {
        this.sideslip.isShow = true
        this.sideslip.text =  "输入确认新密码"
      } else {
        var obj = {
          user_phone: this.user_phone, //登录手机号
          user_password: md5(this.user_password), //原始密码
          m_password: md5(this.m_password), //新密码
          r_password: md5(this.r_password) //确认新密码
        }

        api.modifyPassword(obj).then((res) => {
          if (res.data.code == 200) {
            console.log(res, 333);
            this.sideslip.isShow = true
            this.sideslip.text = res.data.msg
            this.user_phone='' 
            this.user_password='' 
            this.m_password='' 
            this.r_password='' 
          } else {
            this.sideslip.isShow = true
            this.sideslip.text = res.data.msg
          }
        })
      }

    },




    //  tab 切换
    btnTab(val) {
      if (val == 1) {
        this.placeholderPhone = '输入登录手机账号'
        this.placeholderWord = '输入登录密码'
        this.userPassword = ''
        this.code = ''
      } else {
        this.placeholderPhone = '输入注册手机账号'
        this.placeholderWord = '输入注册密码'
        this.userPassword = ''
        this.code = ''   
      }
      this.user_phone='' 
      this.user_password='' 
      this.m_password='' 
      this.r_password='' 
    },
    btnLogin(val) {
      if (val == 1) {
        this.gitLogin()
      } else {
        this.gitRegister()
      }
    },


    // 登录
    gitLogin() {
      var val = JSON.parse(localStorage.getItem('SOHU_CITY_SN'))
      let data = {
        user_phone: this.userPhone,
        user_password: md5(this.userPassword),
        code: this.code,
        login_type: "PC",
        login_cid: val.cid ? val.cid : '', //登录地区码
        login_cip: val.cip ? val.cip : '', //登录IP
        login_cname: val.cname ? val.cname : '' //登录城市
      };

      if (!this.userPhone) {
        console.log("输入手机号")
        this.sideslip.isShow = true
        this.sideslip.text = '输入手机号'

      } else if (!this.userPassword) {
        console.log("输入密码")
        this.sideslip.isShow = true
        this.sideslip.text = '输入密码'

      } else if (!this.code) {
        console.log("验证码")
        this.sideslip.isShow = true
        this.sideslip.text = '验证码'

      } else {
        api.login(data).then((res) => {
          if (res.data.code == 200) {
            this.userPhone = ''
            this.userPassword = ''
            this.code = ''
            this.$store.commit('getLogin', true);
            localStorage.setItem('userInfo', JSON.stringify(res.data.result))
            console.log(res.data.vKey, 1111);
            sessionStorage.setItem('vkey', (res.data.vKey))
            sessionStorage.setItem('uid', (res.data.result.id))
            setTimeout(() => {
              this.$router.push({ path: '/', query: {} })
            }, 100)
          } else {
            this.sideslip.isShow = true
            this.sideslip.text = res.data.msg
            this.yzm()
            this.$store.commit('getLogin', false);

          }
        })
      }
    },

    // 注册
    gitRegister() {
      let data = {
        user_phone: this.userPhone,
        user_password: md5(this.userPassword),
        code: this.code
      };
      if (!this.userPhone) {
        console.log("输入手机号")
        this.sideslip.isShow = true
        this.sideslip.text = '输入手机号'
      } else if (!this.userPassword) {
        console.log("输入密码")
        this.sideslip.isShow = true
        this.sideslip.text = '输入密码'
      } else if (!this.code) {
        console.log("验证码")
        this.sideslip.isShow = true
        this.sideslip.text = '验证码'
      } else {
        api.register(data).then((res) => {
          console.log(res)
          if (res.data.code == 200) {
            this.userPassword = ''
            this.code = ''
            this.userPhone = ''
            this.sideslip.isShow = true
            this.sideslip.text = res.data.msg
            this.btnyzm = false
            setTimeout(() => {
              this.isTab = 1
            }, 1500)
          } else {
            this.sideslip.isShow = true
            this.sideslip.text = res.data.msg


            this.yzm()
          }
        })
      }

    }
  }
}
</script>

<style scoped>
.login {
  padding-top: 60px;
}

.upd {
  text-align: center;
  margin-top: -24px;
  margin-bottom: 12px;
}

.login_mide {
  width: 100%;
}

.login_top {
  width: 100%;
  height: 60px;
  background: #00a0d8;
}

.login_son {
  width: 400px;
  min-height: 220px;
  margin: 130px auto 0;
  border-radius: 6px;
  overflow: hidden;
  background: inherit;
  border-bottom: 1px solid #e2e2e2;
  box-shadow: 0 0 6px rgb(0 0 0 / 6%);
}

.tab_top {
  width: 100%;
  height: 40px;
  background: inherit;
  display: flex;
  color: #333;
}

.top_list {
  flex: 1;
  text-align: center;
  line-height: 40px;
  color: #333;
  cursor: pointer;
}

.top_list.bf {
  background: #fa7199;
  color: #FFFFFF;
}

.btn_input {
  display: block;
  height: 38px;
  background: inherit;
  margin: 12px;
  border: none;
  width: 376px;
  margin: 12px auto;
  padding-left: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  outline: none;
  cursor: pointer;
}

.btn_ok {
  height: 38px;
  background: #fa7199;
  margin: 30px 12px;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  color: #FFFFFF;
  border-radius: 36px;
}

.btn_flew {
  width: 376px;
  margin: 0 auto;
  overflow: hidden;
}

.btn_flew_yzm {
  width: 260px;
  height: 38px;
  background: inherit;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  float: left;
  outline: none;
  padding-left: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

.yzm {
  width: 100px;
  height: 38px;
  float: right;
  /* border: 1px solid rgba(0,0,0,.2); */
  cursor: pointer;
}

.yzms {
  width: 100px;
  height: 38px;
  float: right;
  /* border: 1px solid rgba(0,0,0,.2); */
  cursor: pointer;
  text-align: center;
  line-height: 38px;
  background: #fa7199;
  color: #fff;
}

.aleat {
  background: #e4e4e4;
  color: #000;
}

/deep/ svg {
  width: 100px;
  height: 38px;
}



.text {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 18px;
}

.text:hover {
  color: #fa7199;
  cursor: pointer;
}
</style>
