/**
 * 引入 axios  拦截器
 */
 import apiService from '../utils/service'
 /**
  *  接口 api
  */
 export default {
    imgValid(data) {
         return apiService({
             url: '/base/imgValid?user_phone=' + data,
             method: 'get',
             headers:{},
         })
     },
     register(data) {
        return apiService({
            url: '/user/register',
            method: 'POST',
            headers:{},
            data: data
        })
    },
    login(data) {
        return apiService({
            url: '/user/login',
            method: 'post',
            headers:{},
            data: data
        })
    },
    modifyPassword(data) {
        return apiService({
            url: '/user/modifyPassword',
            method: 'post',
            headers:{},
            data: data
        })
    },
 
 }
 
 